<template>
  <div class="template-doc-content" v-if="docContent">
    <doc-content
        :fileId="fileId"
        :content="docContent"
        :variableConfig="variableConfig"
        :defaultMode="'answer_model'"
    />
  </div>
</template>

<script>
import DocContent from "../../../components/DocContent";
import {
  getMetaProData,
  getTeamMetaApprovalProcesses,
  getTemplateFileMeta,
  getTemplateSettings
} from "../../../api/api";
import {colorLog, MetaMessage} from "../../../util";
import {VARIABLE_TYPE_OPTIONS} from "../../../constant/question";
export default {
  name: "TemplateDocContent",
  components: {DocContent},
  data(){
    return {
      fileId: this.$route.query.fileId,
      templateId: this.$route.query.templateId,
      docContent:null,
      metaMessage: new MetaMessage(),
      teamId:'',
      userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
      teamInfo: JSON.parse(sessionStorage.getItem("teamInfo")),
      publishedData: null,
      metaApprovalProcesses: [],
      publishedTemplate: {
      },
      variableConfig: {
        showVariable: false
      },
    }
  },
  mounted() {
    this.init();
  },
  methods:{
    init: function () {
      this.teamId = this.userInfo.mainSiteTeamId;
      getTeamMetaApprovalProcesses().then(res => {
        if (res.data.code === 0) {
          this.metaApprovalProcesses = res.data.data;
        }
      }).catch(err => {
        this.metaMessage['error']("获取团队权限错误");
      });

      if (this.isNotNull(this.templateId)) {
        this.renderPublishTemplateSetting();
      }else if (this.isNotNull(this.fileId)) {
        getTemplateFileMeta(this.fileId).then(res => {
          console.log('getFileTemplatePublish  res:', res);
          if (res.data.code === 0) {
            if (res.data.data.isPublished) {
              this.renderPublishTemplateSetting();
            }
          } else if (res.data.code === 404) {
            this.metaMessage['error']('资源未找到');
          } else {
            this.metaMessage['error']('获取资源失败 msg:' + res.data.msg);
          }
        }).catch(err => {
          this.metaMessage['error']('获取文件发布模板错误');
        })
        this.initData(this.fileId);
      }

    },
    isNotNull: function (obj) {
      return obj !== null && obj !== undefined && (obj+"").length > 0;
    },
    renderPublishTemplateSetting: function () {
      getTemplateSettings(this.templateId).then(res => {
        colorLog.log('getTemplateSettings', res);
        if (res.data.code === 0) {
          this.publishedData = res.data.data;
          this.initPublishedData(this.publishedData);
          this.fileId = this.publishedTemplate.fileId;
          this.initData(this.publishedTemplate.fileId);
          this.initCategoryStatus += 1;
        } else if (res.data.code === 404) {
          this.metaMessage['error']('资源未找到');
          this.notfound=true;
          //this.goContractHome();
        } else {
          this.metaMessage['error']('获取资源失败 msg:' + res.data.msg);

        }
      }).catch(err => {
        console.log('getTemplateSettings  err:', err);
      })
    },
    initData: function (fileId) {
      getMetaProData(fileId).then(res => {
        const {code, data} = res.data;
        if (code === 0) {
          let docContent = data;
          this.docContent = this.fixTemplateOldData(docContent.type, docContent);
          colorLog.log("this.docContent === ", this.docContent);
        } else {
          if (code === 403) {
            // 没权限
            this.metaMessage['error']("无权限");
          }
        }
      }).catch(err => {
        console.error("getTemplateFileMetaProData  err:", err);
        this.initDataSuccess = false;
      })
    },
    initPublishedData: function () {
      if (this.publishedData == null) return;
      colorLog.blue('initPublishedData  this.publishedData', this.publishedData);
      this.publishedTemplate = this.publishedData.publishedTemplate;
    },
    fixTemplateOldData:function (docType,docContent) {
      if (docType !== 'template') {
        return docContent;
      }
      if(!docContent.variableRects){
        return docContent;
      }
      Object.keys(docContent.variableRects).forEach((key)=>{
        let rect = docContent.variableRects[key];
        if (!rect || VARIABLE_TYPE_OPTIONS.MULTIPLE_OPTION.value !== rect.type) {
          return;
        }
        if (typeof rect.standardAnswer === 'string') {
          rect.standardAnswer = [rect.standardAnswer]
        }
      })
      return docContent;
    },
  }
}
</script>

<style scoped>
.template-doc-content >>> .codex-editor{
  padding: 20px 20px 0 20px;
}
</style>
